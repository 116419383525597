import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Anchor } from '@twilio-paste/anchor';
import { Box } from '@twilio-paste/box';
import { Heading } from '@twilio-paste/heading';
import { ListItem, OrderedList, UnorderedList } from '@twilio-paste/list';
import { DoDont, Do, Dont } from '../../../components/DoDont';
import { Codeblock } from '../../../components/codeblock';
import { SidebarCategoryRoutes } from '../../../constants';
import Changelog from '@twilio-paste/list/CHANGELOG.md';
export const pageQuery = graphql`
  {
    allPasteComponent(filter: {name: {eq: "@twilio-paste/list"}}) {
      edges {
        node {
          name
          description
          status
          version
        }
      }
    }
    mdx(fields: {slug: {eq: "/components/list/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentHeader = makeShortcode("ComponentHeader");
const PageAside = makeShortcode("PageAside");
const LivePreview = makeShortcode("LivePreview");
const ChangelogRevealer = makeShortcode("ChangelogRevealer");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ComponentHeader name={props.pageContext.frontmatter.title} categoryRoute={SidebarCategoryRoutes.COMPONENTS} githubUrl="https://github.com/twilio-labs/paste/tree/main/packages/paste-core/components/list" storybookUrl="/?path=/story/components-list--default-ordered-list" abstractUrl="https://share.goabstract.com/85c81258-90b9-49c8-b34b-9418da5f2f00" data={props.data.allPasteComponent.edges} mdxType="ComponentHeader" />
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <LivePreview scope={{
          UnorderedList,
          ListItem
        }} language="jsx" mdxType="LivePreview">
  {`<UnorderedList>
  <ListItem>
    Tamir Rice
  </ListItem>
  <ListItem>
    Philando Castile
  </ListItem>
  <ListItem>
    Trayvon Martin
  </ListItem>
  <ListItem>
    Breonna Taylor
  </ListItem>
  <ListItem>
    Shantel Davis
  </ListItem>
  <ListItem>
    Walter Scott
  </ListItem>
  </UnorderedList>`}
        </LivePreview>
        <h2>{`Guidelines`}</h2>
        <p>{`Lists can be ordered or unordered. If the order of the list items matters for context to the user, an ordered list should be used. If the meaning of the list is not changed based on the order of the items, an unordered list should be used.`}</p>
        <h3>{`About Lists`}</h3>
        <p>{`Lists are a number of connected list items that are printed consecutively, typically one after the other. Lists can be ordered, using numbers as an indicator to the reader, or unordered with bullet points.`}</p>
        <ul>
          <li parentName="ul">{`Ordered lists are displayed with a preceding number`}</li>
          <li parentName="ul">{`Unordered lists are displayed with a preceding bullet point`}</li>
          <li parentName="ul">{`Lists can be nested as deeply as needed, and they may alternate between ordered and unordered`}</li>
          <li parentName="ul">{`Lists should align with the font-size, font-weight, and line-height of paragraphs, but are styled with bullets or numbers`}</li>
          <li parentName="ul">{`Lists can have similar children elements as paragraphs to provide emphasis on a certain word or phrase`}</li>
        </ul>
        <p>{`List is part of the `}<a parentName="p" {...{
            "href": "/components/typography"
          }}>{`Typography package`}</a>{`, which includes Header and Paragraph as well.`}</p>
        <h3>{`Accessibility`}</h3>
        <ul>
          <li parentName="ul">{`List text should meet AA requirements (4.5:1) for color contrast from itself and the background color`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`ListItem`}</inlineCode>{`s should always have an `}<inlineCode parentName="li">{`OrderedList`}</inlineCode>{` or `}<inlineCode parentName="li">{`UnorderedList`}</inlineCode>{` parent component`}</li>
          <li parentName="ul">{`Nested `}<inlineCode parentName="li">{`ListItem`}</inlineCode>{`s should be wrapped in their own, new `}<inlineCode parentName="li">{`OrderedList`}</inlineCode>{` or `}<inlineCode parentName="li">{`UnorderedList`}</inlineCode>{` parent component, e.g.`}</li>
        </ul>
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`<OrderedList>
  <ListItem>
    Text of the parent list item
    <OrderedList>
      <ListItem>Nested list item</ListItem>
    </OrderedList>
  </ListItem>
</OrderedList>
`}</code></pre>
        <h2>{`Examples`}</h2>
        <h3>{`Unordered List`}</h3>
        <p>{`Use unordered list to display a basic list of items. This is the default variant for List.`}</p>
        <LivePreview scope={{
          UnorderedList,
          ListItem
        }} language="jsx" mdxType="LivePreview">
  {`<UnorderedList>
    <ListItem>
    Tamir Rice
  </ListItem>
  <ListItem>
    Philando Castile
  </ListItem>
  <ListItem>
    Trayvon Martin
  </ListItem>
  <ListItem>
    Breonna Taylor
  </ListItem>
  <ListItem>
    Shantel Davis
  </ListItem>
  <ListItem>
    Walter Scott
  </ListItem>
</UnorderedList>`}
        </LivePreview>
        <h3>{`Ordered List`}</h3>
        <p>{`Use ordered list to display a numbered list of items.`}</p>
        <LivePreview scope={{
          OrderedList,
          ListItem
        }} language="jsx" mdxType="LivePreview">
  {`<OrderedList>
  <ListItem>
    Tamir Rice
  </ListItem>
  <ListItem>
    Philando Casitle
  </ListItem>
  <ListItem>
    Trayvon Martin
  </ListItem>
  <ListItem>
    Breonna Taylor
  </ListItem>
  <ListItem>
    Shantel Davis
  </ListItem>
  <ListItem>
    Walter Scott
  </ListItem>
</OrderedList>`}
        </LivePreview>
        <h3>{`Nesting list items`}</h3>
        <p>{`List items can be nested within other list items. To do so create a new list as a child to a `}<inlineCode parentName="p">{`ListItem`}</inlineCode>{`.`}</p>
        <LivePreview scope={{
          OrderedList,
          UnorderedList,
          ListItem
        }} language="jsx" mdxType="LivePreview">
  {`<div>
  <OrderedList>
    <ListItem>
      Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
      <UnorderedList marginBottom="space0">
        <ListItem>
          Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
        </ListItem>
        <ListItem>
          Duis mollis, est non commodo luctus.
        </ListItem>
      </UnorderedList>
    </ListItem>
    <ListItem>
      Duis mollis, est non commodo luctus.
    </ListItem>
  </OrderedList>
  <UnorderedList>
    <ListItem>
      Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
      <OrderedList marginBottom="space0">
        <ListItem>
          Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
        </ListItem>
        <ListItem>
          Duis mollis, est non commodo luctus.
        </ListItem>
      </OrderedList>
    </ListItem>
    <ListItem>
      Duis mollis, est non commodo luctus.
    </ListItem>
  </UnorderedList>
</div>`}
        </LivePreview>
        <h2>{`Composition Notes`}</h2>
        <h3>{`When to Use a OrderedList and UnorderedList`}</h3>
        <DoDont mdxType="DoDont">
  <Do title="Do" body="Use a list when you have more than one item or point." center mdxType="Do">
    <UnorderedList mdxType="UnorderedList">
      <ListItem mdxType="ListItem">Point one</ListItem>
      <ListItem mdxType="ListItem">Point two</ListItem>
      <ListItem mdxType="ListItem">Point three</ListItem>
    </UnorderedList>
  </Do>
  <Dont title="Don't" body="Don’t use lists for a single item." center mdxType="Dont">
    <UnorderedList mdxType="UnorderedList">
      <ListItem mdxType="ListItem">Point one</ListItem>
    </UnorderedList>
  </Dont>
        </DoDont>
        <DoDont mdxType="DoDont">
  <Do title="Do" body="Use nested lists to provide additional clarity or instruction on specific topics." mdxType="Do">
    <Box padding="space60" mdxType="Box">
      <Heading as="h2" variant="heading30" mdxType="Heading">
        Animals
      </Heading>
      <UnorderedList mdxType="UnorderedList">
        <ListItem mdxType="ListItem">
          Mammals
          <UnorderedList marginBottom="space0" mdxType="UnorderedList">
            <ListItem mdxType="ListItem">Cat</ListItem>
            <ListItem mdxType="ListItem">Dog</ListItem>
          </UnorderedList>
        </ListItem>
        <ListItem mdxType="ListItem">
          Birds
          <UnorderedList marginBottom="space0" mdxType="UnorderedList">
            <ListItem mdxType="ListItem">Chicken</ListItem>
            <ListItem mdxType="ListItem">Turkey</ListItem>
          </UnorderedList>
        </ListItem>
      </UnorderedList>
    </Box>
  </Do>
  <Dont title="Don't" body="Don’t nest lists if the nested content is unrelated to the higher level topic." mdxType="Dont">
    <Box padding="space60" mdxType="Box">
      <Heading as="h2" variant="heading30" mdxType="Heading">
        Animals
      </Heading>
      <UnorderedList mdxType="UnorderedList">
        <ListItem mdxType="ListItem">
          Mammals
          <Heading as="h3" variant="heading40" mdxType="Heading">
            Groceries
          </Heading>
          <UnorderedList marginBottom="space0" mdxType="UnorderedList">
            <ListItem mdxType="ListItem">Milk</ListItem>
            <ListItem mdxType="ListItem">Butter</ListItem>
          </UnorderedList>
        </ListItem>
        <ListItem mdxType="ListItem">
          Birds
          <UnorderedList marginBottom="space0" mdxType="UnorderedList">
            <ListItem mdxType="ListItem">Chicken</ListItem>
            <ListItem mdxType="ListItem">Turkey</ListItem>
          </UnorderedList>
        </ListItem>
      </UnorderedList>
    </Box>
  </Dont>
        </DoDont>
        <h2>{`Anatomy`}</h2>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default token`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Modifiable?`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`font-size`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`font-size-30`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`line-height`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`line-height-30`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`font-weight`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`font-weight-normal`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`margin-top`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Yes`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`margin-bottom`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`space-70`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Yes`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`color`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`color-text`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
          </tbody>
        </table>
        <hr></hr>
        <h2>{`Usage Guide`}</h2>
        <h3>{`API`}</h3>
        <h4>{`Installation`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`yarn add @twilio-paste/list - or - yarn add @twilio-paste/core
`}</code></pre>
        <h4>{`Usage`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`import {OrderedList, UnorderedList, ListItem} from '@twilio-paste/list';

const Component = () => (
  <UnorderedList>
    <ListItem>Point one</ListItem>
    <ListItem>Point two</ListItem>
    <ListItem>Point three</ListItem>
  </UnorderedList>
);

const Component = () => (
  <OrderedList>
    <ListItem>Point one</ListItem>
    <ListItem>Point two</ListItem>
    <ListItem>Point three</ListItem>
  </OrderedList>
);
`}</code></pre>
        <h4>{`Props`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`children?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ReactNode`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`marginTop?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ResponsiveValue<keyof ThemeShape['space']>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`marginBottom?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ResponsiveValue<keyof ThemeShape['space']>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`'space70'`}</td>
            </tr>
          </tbody>
        </table>
        <ChangelogRevealer mdxType="ChangelogRevealer">
  <Changelog mdxType="Changelog" />
        </ChangelogRevealer>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      